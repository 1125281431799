<template>
  <v-dialog v-model="dialogStatus" width="450">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Please verify your email</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <div>
          Inorder to start using trading bot, you need to confirm your email address.
        </div>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogStatus = false">Close</v-btn>
        <v-btn text @click="goToPage('/verifyemail')">Verify Now</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>

export default {
  name: "VerifyDialogRobotDetail",
  props: ["dialogStatus", "dialogData"],
  
  data: () => ({

  }),
  mounted() {},
  watch: {
  },
  computed: {
  },
  methods: {
    goToPage(gotoPath) {
      this.$router.push({ path: gotoPath });
    },
  },
};
</script>