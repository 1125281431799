<template>
  <v-dialog v-model="show" fullscreen>
    <v-card height="50vh">
      <v-toolbar>
        <v-toolbar-title>Growth Chart</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column" style="height: 91%">
        <v-card height="70%" color="#172033">
          <GrowthChart v-bind:data="render_growth_option" />
        </v-card>
        <v-card height="30%" color="#172033">
          <GrowthChart class="pa-1" v-bind:data="render_dd_option" />
        </v-card>
      </div>

      <!-- -->
    </v-card>
  </v-dialog>
</template>

<script>
//
import { getRobotDetailAnalyticEquity } from "@/services/api/robot";
import { getLocalTimeFromTimestampList } from "@/utils/customDate";
import GrowthChart from "../charts/GrowthChart";
//
export default {
  name: "GrowthDDDialog",
  props: ["dialogStatus", "robotId"],
  components: {
    GrowthChart,
  },
  data: () => {
    return {
      render_growth_option: {},
      render_dd_option: {},
    };
  },
  methods: {
    async getData() {
      let _chart_data = await getRobotDetailAnalyticEquity(this.robotId);
      // process timestamp
      _chart_data["equity_chart"]["daily"]["timeStamp"] =
        getLocalTimeFromTimestampList(
          _chart_data["equity_chart"]["daily"]["timeStamp"]
        );
      //
      _chart_data["max_dd_chart"]["daily"]["timeStamp"] =
        getLocalTimeFromTimestampList(
          _chart_data["max_dd_chart"]["daily"]["timeStamp"]
        );

      this.render_growth_option = {
        startCapital: _chart_data["equity_chart"]["startCapital"],
        data: _chart_data["equity_chart"]["daily"]["value"],
        xAxisData: _chart_data["equity_chart"]["daily"]["timeStamp"],
        yAxisLabel: "Equity",
      };

      this.render_dd_option = {
        startCapital: null,
        data: _chart_data["max_dd_chart"]["daily"]["value"],
        xAxisData: _chart_data["max_dd_chart"]["daily"]["timeStamp"],
        yAxisLabel: "Drawdown",
      };
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
</style>