<template>
  <v-dialog v-model="show" fullscreen>
    <v-card height="100%" color="#172033">
      <v-toolbar>
        <v-toolbar-title>Win Vs Loss</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column" style="height: 91%">
        <v-card height="80%" class="pt-3" color="#172033">
          <v-chart class="chart" :option="option" autoresize />
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import _ from "lodash";
use([
  DatasetComponent,
  GridComponent,
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

import { getRobotDetailWinLoss } from "@/services/api/robot";
export default {
  name: "WinVsLossDialog",
  props: ["dialogStatus", "robotId"],
  components: {
    VChart,
  },
  data: () => {
    return {
      option: null,
      totalWinLossData: [],
      default_option: {
        dataset: {
          source: [],
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "6%",
          containLabel: true,
        },
        xAxis: {
          name: "Day",
          type: "category",
          axisLabel: {
            color: "#fff",
          },
          nameTextStyle: {
            color: "#fff",
          },
          nameLocation: "middle",
          nameGap: 30,
        },
        yAxis: {
          // name: "win",
          type: "value",
          axisLabel: {
            color: "#fff",
          },
          nameTextStyle: {
            color: "#fff",
          },
          name: "Trade",
          nameLocation: "middle",
          nameGap: 50,
        },
        series: [
          {
            name: "Win",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: (d) =>
                `${parseFloat((d.value[1] / d.value[3]) * 100).toFixed(2)}%`,
            },
            encode: {
              x: "day",
              y: "win",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: { color: "#76FF03" },
          },
          {
            name: "Loss",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              formatter: (d) =>
                `${parseFloat((d.value[2] / d.value[3]) * 100).toFixed(2)}%`,
            },
            itemStyle: { color: "#FF1744" },
            encode: {
              x: "day",
              y: "loss",
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      },
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    };
  },
  methods: {
    async getData() {
      let _chart_data = await getRobotDetailWinLoss(this.robotId);
      let _option = _.clone(this.default_option);
      // precess
      let _winLossTotal = [];
      _winLossTotal.push(["day", "win", "loss", "total"]);
      // ["win", "loss", "total"]
      for (let i = 0; i < this.days.length; i++) {
        // calculate percent
        let _winV =
          _chart_data["win_loss_chart"][this.days[i].toLowerCase()][
            "win_count"
          ];
        let _lossV =
          _chart_data["win_loss_chart"][this.days[i].toLowerCase()][
            "loss_count"
          ];
        _winLossTotal.push([this.days[i], _winV, _lossV, _winV + _lossV]);
      }
      //set
      _option["dataset"]["source"] = _winLossTotal;
      this.option = _option;
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
</style>

