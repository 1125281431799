import moment from "moment";

export function getLocalTimeFromTimestampList(d) {
  // .unix(d[0])
  return d.map((x) => moment.unix(x).format("YYYY-MM-DD"));
}

export function checkIPv4(ipVal) {
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  // .unix(d[0])
  return regexExp.test(ipVal);
}
