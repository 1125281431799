<template>
  <v-dialog v-model="show" fullscreen>
    <v-card height="100%" color="#172033">
      <v-toolbar>
        <v-toolbar-title>Monthly Yearly Return</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column" style="height: 91%">
        <v-card>
          <!-- table -->
          <v-simple-table style="background: #0C1322;">
            <template v-slot:default color="#1D3149">
              <thead>
                <tr>
                  <th class="text-left" v-for="item in years" :key="item.en">
                    <span class="text-subtitle-1"> {{ item.en }} </span> <br />
                    <!-- <span v-if="item.th !== ''"> ({{ item.th }}) </span> -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(block, index) in table_data" :key="'year' + index">
                  <td
                    v-for="(item, subIndex) in block"
                    :key="'subyear' + subIndex"
                    :style="table_style[index][subIndex]"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { getRobotDetailMonthlyReturn } from "@/services/api/robot";
//
export default {
  name: "MonthlyYearlyReturnDialog",
  props: ["dialogStatus", "robotId"],
  components: {},
  data: () => {
    return {
      years: [
        {
          en: "Year",
          th: "",
        },
        {
          en: "JAN",
          th: "ม.ค.",
        },
        {
          en: "FEB",
          th: "ก.พ.",
        },
        {
          en: "MAR",
          th: "มี.ค.",
        },
        {
          en: "APR",
          th: "เม.ย.",
        },
        {
          en: "MAY",
          th: "พ.ค.",
        },
        {
          en: "JUN",
          th: "เม.ย.",
        },
        {
          en: "JUL",
          th: "ก.ค.",
        },
        {
          en: "AUG",
          th: "ส.ค.",
        },
        {
          en: "SEP",
          th: "ก.ย.",
        },
        {
          en: "OCT",
          th: "ต.ค.",
        },
        {
          en: "NOV",
          th: "พ.ย.",
        },
        {
          en: "DEC",
          th: "ธ.ค.",
        },
        {
          en: "Yearly return",
          th: "",
        },
      ],
      table_data: [],
      table_style: [],
    };
  },
  methods: {
    calZone(currentPercent) {
      // background color = 12, 19, 34
      let baseColor = 150;
      let defaultRedColor = 12;
      let remainingRedColor = 255 - (baseColor + defaultRedColor);
      let defaultGreenColor = 19;
      let remainingGreenColor = 255 - (baseColor + defaultGreenColor);
      let defaultBlueColor = 34;
      // let remainingBlueColor = 255 - defaultBlueColor;
      let fracionValue = 70; // 70 pecent
      let redWeight = 3.5;
      let greenWeight = 3.5;
      let percentOfChange = 0;
      let returnStr = "";
      let newRedColor = 0;
      let newGreenColor = 0;
      let newBlueColor = 0;

      if (currentPercent < 0.0) {
        // Red up and Green and Blue down
        // calculate percent of change (30%)
        // baseColor + (remainingRedColor*percentOfChange)
        percentOfChange = (currentPercent * -1) / fracionValue;
        newRedColor = parseInt(baseColor + remainingRedColor * percentOfChange);

        // decrease green and blue
        newGreenColor = parseInt(
          defaultGreenColor - defaultGreenColor * percentOfChange
        );
        newBlueColor = parseInt(
          defaultBlueColor - defaultBlueColor * percentOfChange
        );
        // find max value to decrease the over red
        // weight control value (more)
        let maxVal =
          (newGreenColor > newBlueColor ? newGreenColor : newBlueColor) *
          redWeight;
        //
        newRedColor -= maxVal;
        newRedColor = newRedColor > 180 ? 180 : newRedColor;
        newRedColor =
          newRedColor < defaultRedColor ? defaultRedColor : newRedColor;

        returnStr = `rgb(${newRedColor},${newGreenColor},${newBlueColor})`;
      } else if (currentPercent == 0.0) {
        returnStr = `rgb(${defaultRedColor},${defaultGreenColor},${defaultBlueColor})`;
      } else {
        // remainingGreenColor change
        percentOfChange = currentPercent / fracionValue;
        percentOfChange = percentOfChange > 1 ? 1 : percentOfChange;
        newGreenColor = parseInt(
          baseColor + remainingGreenColor * percentOfChange
        );

        newRedColor = parseInt(
          defaultRedColor - defaultRedColor * percentOfChange
        );
        newBlueColor = parseInt(
          defaultBlueColor - defaultBlueColor * percentOfChange
        );
        //
        let maxVal =
          (newRedColor > newBlueColor ? newRedColor : newBlueColor) *
          greenWeight;
        newGreenColor -= maxVal;
        newGreenColor = newGreenColor > 180 ? 180 : newGreenColor;
        newGreenColor =
          newGreenColor < defaultGreenColor ? defaultGreenColor : newGreenColor;

        returnStr = `rgb(${newRedColor},${newGreenColor},${newBlueColor})`;
      }
      // console.log(
      //   "calculate percent",
      //   currentPercent,
      //   currentCalValue,
      //   returnStr
      // );
      returnStr = `background: ${returnStr};`;
      return returnStr;
    },
    async getData() {
      let _chart_data = await getRobotDetailMonthlyReturn(this.robotId);
      let _pre_process_data = [];
      let _pre_process_style = [];
      for (var key in _chart_data["monthly_return_chart"]) {
        let _each_row = [];
        let _each_row_style = [];
        //
        _each_row.push(key);
        _each_row_style.push("");

        for (
          let i = 0;
          i < _chart_data["monthly_return_chart"][key].length;
          i++
        ) {
          let fixedNumberData = parseFloat(
            _chart_data["monthly_return_chart"][key][i]
          ).toFixed(2);
          _each_row.push(fixedNumberData);
          //
          _each_row_style.push(this.calZone(fixedNumberData));
        }
        _pre_process_data.push(_each_row);
        _pre_process_style.push(_each_row_style);
      }
      this.table_data = _.clone(_pre_process_data);
      this.table_style = _.clone(_pre_process_style);
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
</style>

