<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  // VisualMapPiecewiseComponent,
} from "echarts/components";
// import VChart, { THEME_KEY } from "vue-echarts";
import VChart from "vue-echarts";
import _ from "lodash";

use([
  GridComponent,
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  // VisualMapPiecewiseComponent,
]);

export default {
  name: "GrowthChart",
  props: ["data"],
  components: {
    VChart,
  },
  data: () => {
    return {
      option: {},
      default_line_option: {
        grid: {
          // backgroundColor: "#0C2136",
          show: true,
          borderWidth: 0,
          top: 20,
          bottom: 45,
          right: "2%",
          left: "7%",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b0} </br> {c0} %",
        },
        xAxis: {
          // name: 'Date',
          type: "category",
          // boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: ["#fff"],
            },
          },
          lineStyle: {
            type: "dashed",
          },
          axisLabel: {
            color: "#fff",
            margin: 20,
          },
          // nameTextStyle: {
          //   padding: [3, 4],
          // },
        },
        yAxis: {
          // name: 'Growth',
          type: "value",
          splitLine: {
            lineStyle: {
              color: ["#293B51"],
            },
          },
          axisLabel: {
            color: "#fff",
            formatter: "{value} %",
          },
          name: "",
          nameLocation: "middle",
          nameGap: 50,
        },
        // visualMap: {
        //   type: "piecewise",
        //   show: false,
        //   dimension: 0,
        //   pieces: [
        //     {
        //       lte: 0,
        //       color: "#FD0100",
        //     },
        //     {
        //       gt: 0,
        //       color: "#93CE07",
        //     },
        //   ],
        //   outOfRange: {
        //     color: "#93CE07",
        //   },
        // },
        series: [
          {
            type: "line",
            data: [],
            showSymbol: false,
            areaStyle: {},
            // color: "green",
            smooth: true,
            // encode: {
            //   x: "Date",
            //   y: "Growth",
            //   itemName: "Date",
            //   tooltip: ["Growth"],
            //
            // },
          },
        ],
      },
    };
  },
  methods: {
    processData(d) {
      let new_d = [];
      for (let i = 0; i < d["data"].length; i++) {
        new_d.push(
          parseFloat(
            ((d["data"][i] - d["startCapital"]) * 100.0) / d["startCapital"],
            3
          ).toFixed(3)
        );
        //new_d.push(parseFloat((d["data"][i] / d["startCapital"]) * 100, 3).toFixed(3));
      }
      return new_d;
    },
  },
  watch: {
    data: function (newVal) {
      let newValData = _.clone(newVal);
      let _option = _.clone(this.default_line_option);
      if (newValData["startCapital"] !== null) {
        newValData["processed_process"] = this.processData(newValData);
        _option["series"][0]["color"] = '#93CE07';
      } else {
        newValData["processed_process"] = newValData["data"];
        _option["series"][0]["color"] = '#FD0100';
      }
      //   console.log("Prop changed: ", newVal, " | was: ", oldVal);
      _option["series"][0]["data"] = newValData["processed_process"];
      _option["xAxis"]["data"] = newValData["xAxisData"];
      _option["yAxis"]["name"] = newValData["yAxisLabel"]
      this.option = _option;
    },
  },
  mounted() {
    // console.log(this.data);
  },
};
</script>

<style scoped>
</style>
