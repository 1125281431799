<template>
  <v-dialog v-model="show" fullscreen>
    <v-card height="100%" color="#172033">
      <v-toolbar>
        <v-toolbar-title>Histogram of Return</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column" style="height: 91%">
        <DailyReturnChart
          :chartData="render_data"
          :options="render_option"
          :styles="{ height: '80%', width: '100%', position: 'relative' }"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
//
import { getRobotDetailHistogramOfReturn } from "@/services/api/robot";
import _ from "lodash";
import DailyReturnChart from "../charts/DailyReturnChart";
//
export default {
  name: "HistogramDDDialog",
  props: ["dialogStatus", "robotId"],
  components: { DailyReturnChart },
  data: () => {
    return {
      chart_default_chartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "#f1f1f1", //[],
            barPercentage: 1.1,
          },
        ],
      },
      chart_default_options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              display: true,
              // barPercentage: 1.1,
              ticks: {
                max: 0, // update list
                fontColor: "#fff",
                callback: function (value) {
                  return `${value} %`;
                },
              },
              scaleLabel: {
                display: true,
                labelString: "% Net Profit",
                fontColor: "#fff",
              },
              //   gridLines: {
              //     color: "#fff",
              //   },
            },
            // {
            //   display: true,
            //   ticks: {
            //     autoSkip: false,
            //     max: 4,
            //   },
            // },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Trade",
                fontColor: "#fff",
              },
              ticks: {
                beginAtZero: true,
                fontColor: "#fff",
              },
              gridLines: {
                color: "#293B51",
              },
            },
          ],
        },
      },
      render_data: {},
      render_option: {},
    };
  },
  methods: {
    processDailyReturnChart(data) {
      let pre_chart_default_chartdata = _.clone(this.chart_default_chartdata);
      let pre_chart_default_options = _.clone(this.chart_default_options);

      pre_chart_default_chartdata["labels"] = data["x"];
      pre_chart_default_chartdata["datasets"][0]["data"] = data["y"];
      pre_chart_default_options["scales"]["xAxes"][0]["ticks"]["max"] =
        data["x"].length;
      // background process
      let background_color = [];
      for (let i = 0; i < data["x"].length; i++) {
        background_color.push(data["x"][i] < 0 ? "red" : "green");
      }
      pre_chart_default_chartdata["datasets"][0]["backgroundColor"] =
        background_color;
      //
      this.render_data = pre_chart_default_chartdata;
      this.render_option = pre_chart_default_options;
    },
    async getData() {
      let _chart_data = await getRobotDetailHistogramOfReturn(this.robotId);
      this.processDailyReturnChart(_chart_data["histogram_of_return_chart_l"]);
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
</style>